<template>
  <v-app>
    <core-snackbar />

    <v-container fluid class="grey lighten-2 fill-height">
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-row no-gutters justify="center">
            <v-col class="no-gutters" cols="4"> </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="pt-0">
          <v-row no-gutters justify="center">
            <v-col class="no-gutters" md="4">
              <v-card>
                <v-toolbar dense flat dark color="primary">
                  <v-toolbar-title>
                    Login
                  </v-toolbar-title>
                </v-toolbar>
                <v-form @submit="login">
                  <v-row class="pa-4">
                    <v-col>
                      <validation-observer ref="observer">
                        <validation-provider v-slot="{ errors }" vid="username">
                          <v-text-field
                            v-model="form.username"
                            hide-details="auto"
                            label="Usuário"
                            class="mb-4"
                            autocomplete="nope"
                            :error-messages="errors"
                            prepend-icon="mdi-account-circle"
                          />
                        </validation-provider>
                        <validation-provider v-slot="{ errors }" vid="password">
                          <v-text-field
                            v-model="form.password"
                            hide-details="auto"
                            type="Password"
                            label="Senha"
                            :error-messages="errors"
                            prepend-icon="mdi-lock"
                          />
                        </validation-provider>
                      </validation-observer>
                    </v-col>
                  </v-row>
                  <v-row class="mt-4 px-4 pt-0 pb-1">
                    <v-col class="text-right">
                      <v-btn
                        color="primary"
                        outlined
                        :loading="loading"
                        type="submit"
                      >
                        <v-icon class="mr-2">
                          mdi-login
                        </v-icon>
                        Entrar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { mapMutations } from 'vuex'
import authApi from '@/api/auth'

export default {
  components: {
    CoreSnackbar: () => import('@/components/general/core/CoreSnackbar'),
  },

  data: () => ({
    loading: false,
    form: {
      username: null,
      password: null,
    },
  }),

  methods: {
    ...mapMutations({
      setToken: 'user/SET_TOKEN',
      setUser: 'user/SET_USER',
    }),

    async login(event) {
      try {
        event.preventDefault()
        this.loading = true

        const response = await authApi.login(this.form)

        this.setToken(response.data.token)
        this.setUser(response.data.user)

        this.$router.push('/admin')
      } catch (e) {
        if (e.response && e.response.status === 422) {
          return this.$refs.observer.setErrors(
            this.$apiError.fieldsValidation(e),
          )
        }

        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
